'use client'
import React, { useEffect, useState, useTransition, useCallback, useMemo } from 'react'
import { toPairs } from 'lodash-es'
import JobSearchBar from 'app/components/commons/location/search'
import styles from 'app/index.module.scss'
import theme from 'app/components/commons/theme'
import { ThemeProvider } from '@mui/material/styles'
import MaterialButton from 'components/MaterialButton'
import useSearchHistory from 'helpers/useSearchHistory'
import { useRouter } from 'next/navigation'
import Image from 'next/image'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import QrCodeDraw from 'components/QrCodeDraw/QrCodeDraw'
import LocationMultiSelector from 'app/components/commons/locationMultyNew'
import { encode, zipLocation } from 'app/(jobs-hiring)/[lang]/jobs-hiring/interpreters/encoder'
import { setCookie } from 'helpers/cookies'
import { HistoryIcons, footer_apple_download, footer_googleplay_download } from 'images'
import Link from 'components/Link'
import { homeHeaderPhoneBg, downloadApp } from 'images/svg'
import { fetchSearchSuggestionService } from 'store/services/jobs/fetchSearchSuggestion'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { isEmpty } from 'lodash-es'
import { addEventTag, convertSpecialCharacters } from 'helpers/utilities'
import adjustUrlList from 'helpers/adjustUrlList'
const transObject = (params) => {
  const result = {}
  params.forEach((e, index) => {
    result[`query_histories[${index}]`] = e
  })
  return result
}

const google_tags = ['hp_location_click', 'hp_search_focus', 'hp_search_click']

const SearchArea = (props: any) => {
  const { langKey, isMobile, pageData } = props
  const searchInfo = pageData?.search_info || {}

  // console.log('SearchArea', searchInfo)

  const { location, setLocation } = useSharedData('LOCATION_CONTEXT') ?? {}
  const locations = useMemo(() => {
    if (isEmpty(location)) {
      return []
    } else if (Array.isArray(location)) {
      return location
    } else {
      return [location]
    }
  }, [location])
  const router = useRouter()
  const [isShow, setIsShow] = useState(false)
  useEffect(() => {
    window.document.addEventListener('scroll', useFn)
    return () => {
      window.document.removeEventListener('scroll', useFn)
    }
  }, [])

  useEffect(() => {
    if (location) {
      setCookie('location', location)
      router.refresh()
    }
  }, [location])

  const pushJobPage = async (value = '', type = '') => {
    const keyWords = value?.trim?.().replace(/\s+/g, ' ')
    const result = encode({
      // query: value?.trim?.(),
      query: convertSpecialCharacters(keyWords),
      location: locations?.map((a) => a['seo_value']),
      queryFields: type
    })
    const zippedParams = await zipLocation(result.params)
    const url = new URLSearchParams(toPairs(zippedParams)).toString()
    window.location.href =
      '/' + langKey + '/jobs-hiring/' + result.searchQuery + (url ? '?' + url : '')
  }

  const [suggestionList, setSuggestionList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [searchHistories, addSearchHistory] = useSearchHistory()
  const [, transitionStart] = useTransition()
  const isPC = !isMobile

  const handleSuggestionSearch = useCallback(
    (val) => {
      transitionStart(() => {
        const valueLength = val?.length ?? 0
        if (valueLength === 0) {
          setSuggestionList(searchHistories as any)
        } else if (valueLength === 1) {
          setSuggestionList([])
        } else if ((val?.length ?? 0) > 1) {
          const qs = transObject(searchHistories)
          fetchSearchSuggestionService({ size: 5, query: val, ...qs }).then((data) =>
            setSuggestionList(data.data.data.items)
          )
        }
      })
    },
    [transitionStart]
  )
  const throttle = (func, delay) => {
    let timer = null
    return function () {
      if (!timer) {
        timer = setTimeout(() => {
          func()
          timer = null
        }, delay)
      }
    }
  }

  const isTouchBottom = () => {
    if (isPC) {
      const scrollTopHeight = document.body.scrollTop || document.documentElement.scrollTop

      if (scrollTopHeight > 180) {
        return setIsShow(true)
      }
    }
    setIsShow(false)
  }
  const useFn = throttle(() => {
    isTouchBottom()
  }, 300)

  const styleleSelect = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }

  const spanStyle = {
    paddingLeft: '10px',
    fontSize: '15px'
  }
  // console.log({ location })

  return (
    <div className={`${styles.searchArea} ${isShow ? styles.searchAreaFix : ''}`}>
      <ThemeProvider theme={theme}>
        <div className={styles.box}>
          <div className={styles.searchWrapper}>
            <LocationMultiSelector
              className={styles.location}
              value={location}
              label={searchInfo?.location_text || ''}
              locationData={searchInfo?.location_lists || []}
              onSelect={(ev) => {
                setLocation?.(ev)
                // console.log({ locationev: ev })
                addEventTag(google_tags[0])
              }}
              lang={{
                reset: searchInfo?.reset,
                save: searchInfo?.save,
                search: searchInfo?.search_text
              }}
              top={79}
              isMobile={isMobile}
              isPC={isPC}
              inputFontSize='16px'
              sx={{
                '> .MuiFormControl-root': {
                  borderRadius: '8px',
                  height: '60px',
                  marginTop: '4px',
                  overflow: 'hidden',
                  '> .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    height: '60px',
                    overflow: 'hidden',
                    marginTop: '4px'
                  }
                }
              }}
            />
            <div className={styles.searchSpread}></div>
            <div style={{ display: 'flex' }} className={styles.searchBox}>
              <JobSearchBar
                id='search'
                // label={home.search.title}
                placeholder={searchInfo.input_text}
                variant='outlined'
                size='small'
                className={styles.search}
                value={searchValue}
                maxLength={255}
                searchFn={handleSuggestionSearch}
                updateSearchValue={setSearchValue}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault()
                    const value = (e.target as HTMLInputElement).value
                    setSearchValue(value)
                    addSearchHistory(value)
                    if (value) {
                      pushJobPage(value, '')
                    }
                  }
                }}
                options={suggestionList}
                onSelect={(value: any) => {
                  const newValue = value?.value || value || ''
                  const type = value?.type || ''
                  setSearchValue(newValue)
                  addSearchHistory(newValue)
                  if (newValue) {
                    pushJobPage(newValue, type)
                  }
                }}
                onFocus={(ev) => {
                  addEventTag(google_tags[1])
                }}
                renderOption={(props, option) => {
                  const { type, is_history: isHistory, value, logo_url: logoUrl } = option || {}
                  return type === 'company' ? (
                    <li {...props} style={styleleSelect} key={props.id}>
                      <Image src={logoUrl} alt={value} width='22' height='22' />
                      <span style={spanStyle}>{value}</span>
                    </li>
                  ) : isHistory ? (
                    <li {...props} style={{ ...styleleSelect, color: '#136fd3' }} key={props.id}>
                      <AccessTimeIcon />
                      <span style={spanStyle}>{value}</span>
                    </li>
                  ) : (
                    <li {...props} style={styleleSelect} key={props.id}>
                      <Image src={HistoryIcons} alt='history icons' width='17' height='17' />
                      <span style={spanStyle}>{value || option}</span>
                    </li>
                  )
                }}
              />
              <MaterialButton
                className={styles.searchButton}
                onClick={() => {
                  // if (!searchValue) return
                  searchValue && addSearchHistory(searchValue)
                  pushJobPage(searchValue, '')
                  addEventTag(google_tags[2])
                }}
                style={{
                  textTransform: 'capitalize'
                }}
              >
                {searchInfo.search_text}
              </MaterialButton>
            </div>
          </div>
          {isShow && (
            <div
              className={styles.download}
              style={{ minWidth: langKey === 'ja-JP' ? '216px' : '178px' }}
            >
              <div className={styles.icon}>{downloadApp}</div>
              <p>
                {' '}
                {searchInfo?.download}
                <br />
                <span>{searchInfo?.chat_boss}</span>
              </p>
              <div className={styles.popver}>
                <div className={styles.popverContainer}>
                  <div className={styles.popverMain}>
                    <div className={styles.info}>
                      <h5 className={styles.getApp}>{searchInfo?.get_the_dice_app}</h5>
                      <p className={styles.getAppTips}>{searchInfo?.you_must_install_app}</p>
                      <div>
                        <Link
                          to={adjustUrlList[3].link}
                          external
                          style={{ marginRight: '20px', display: 'inline-block' }}
                        >
                          <Image
                            src={footer_apple_download}
                            alt='AppStore'
                            width={140}
                            height={42}
                          />
                        </Link>
                        <Link
                          to={adjustUrlList[3].link}
                          external
                          style={{ display: 'inline-block' }}
                        >
                          <Image
                            src={footer_googleplay_download}
                            alt='GooglePlay'
                            width={140}
                            height={42}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className={styles.code}>
                      <div className={styles.homeBg}>{homeHeaderPhoneBg}</div>
                      <div className={styles.qrcode}>
                        <QrCodeDraw text={adjustUrlList[3].link} ecl='H' width={128} height={128} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </ThemeProvider>
    </div>
  )
}
export default SearchArea
